import React, { useEffect } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { postData, getData } from "../../services/HttpServices";
import { AlertSuccess, AlertError } from "../../components/Alerts";

const Login = () => {
    const navigate = useNavigate();
const DEMOMODE = process.env.REACT_APP_DEMOMODE
    useEffect(() => {
        const UserToken = localStorage.getItem('iq')
        if (UserToken) {
            const getCurrentUser = () => {
                getData('/currentloggedin', ).then((response) => {
                    let userData = response.data
                    if (response.status === 200) {
                        if (userData.success) {
                            navigate('/dashboard')
                        } else {
                            AlertError(response.message)
                        }
                    } else {
                        AlertError(response.message)
                    }
                })
            }
            getCurrentUser()

        }
    }, [navigate])

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => {
        postData('/login', data).then((response) => {
            let resposnseData = response.data
            if (resposnseData.success === false) {
                AlertError(resposnseData.message);
                return
            }
            localStorage.setItem("iq", resposnseData.data);
            AlertSuccess(resposnseData.message)
            setTimeout(function() {
                navigate('/dashboard')
            }, 1000)

        }).catch(e => {
            AlertError(e);
        })
    }
    return (
        <AuthLayout>
            <div className="col-lg-3 col-md-4 offset-md-2 offset-lg-2 d-flex flex-column">
                                    <div className="d-flex justify-content-center py-4">
                                        <a href="/" className="logo d-flex align-items-center w-auto">
                                            <span className="d-none d-lg-block">
                                                <img src="images/logo.png" alt=""/>
                                            </span>
                                        </a>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="">
                                            {DEMOMODE == "true" && <span class="badge bg-warning text-dark mt-2"> This is a development environment. </span> }
                                                <h2 className="card-title pb-0 fs-4">Login</h2>
                                            </div>
                                            <form className="row g-3 needs-validation" onSubmit={handleSubmit(onSubmit)}>
                                                <div className="col-12">
                                                    <div className="input-group has-validation">
                                                        <input type="email" name="email" className="form-control" placeholder="Email"  {...register("email", {required: true, pattern: /^\S+@\S+$/i})}  />
                                                        
                                                    </div>
                                                    {errors?.email && <span className="error">Please enter valid email</span>}
                                                </div>
                                                <div className="col-12">
                                                    <input type="password" name="password" className="form-control" placeholder="Password" {...register("password", {required: true , maxLength: 20})} />
                                                    {errors?.password && errors.password.type === "required" && <span>Please enter password</span>}
                                                    {errors?.password && errors.password.type === "maxLength" && <span>Max length exceeded</span> }
                                                    
                                                </div>
                                                {/* <div className="col-6">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="remember" id="rememberMe" value="1"  />
                                                        <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                                                    </div>
                                                </div> */}
                                                <div className="col-12">
                                                    <div className="text-end">
                                                        <a href="/forgot">Forgot Password ?</a>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <button className="btn btn-primary w-100" type="submit">Login</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="credits text-center">
                                        Designed by <a href="/">RKM Software</a>
                                    </div>
                                </div>
        </AuthLayout>
    )
}

export default Login;